@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Croissant+One&family=Great+Vibes&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");

*,
html,
body {
  margin: 0;
  padding: 0;
  width: "100%";
  height: "100%";
  scroll-behavior: "smooth";
}

.gold-text {
  color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );

  -webkit-background-clip: text;
  background-clip: text;
}

.box-countdown-date {
  @apply w-16 h-16 rounded-md text-center flex flex-col justify-center items-center text-sm font-prata font-medium leading-6 tracking-wide;
}

.box-countdown-date p {
  @apply text-pretty text-shadow;
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.input-remark {
  @apply outline-none border border-beige rounded-lg bg-sageLight p-2 min-h-8 w-full shadow-sm placeholder:italic;
}

.glass-emerald {
  /* From https://css.glass */
  background: rgba(22, 58, 57, 0.67);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.8px);
  -webkit-backdrop-filter: blur(6.8px);
  border: 1px solid rgba(22, 58, 57, 0.26);
}
.glass-sage-light {
  background: rgba(167, 216, 197, 0.35);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(167, 216, 197, 0.33);
}

.glass-beige {
  /* From https://css.glass */
  background: rgba(254, 251, 233, 0.44);
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(254, 251, 233, 0.3);
}

/* Untuk Google Chrome, Edge, dan Safari */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Untuk Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Untuk Internet Explorer dan Edge (Legacy) */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-right {
  animation: slide-in-right 1s ease-out forwards;
}

.input-with-icon {
  position: relative;
  padding-left: 2.5rem; /* Adjust padding to make room for the icon */
  color: #ffff;
}

.input-with-icon::after {
  content: " ";
  position: absolute;
  left: 0.75rem; /* Adjust position as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 1rem; /* Adjust size as needed */
  height: 1rem; /* Adjust size as needed */
  background-image: url("/src/assets/image/icon-send.svg"); /* Path to your SVG icon */
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none; /* Ensure the icon does not interfere with input interaction */
}
